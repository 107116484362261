<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('StudentResults')"
      :subtitle="$t('Students')"
      :root="$t('Evaluation')"
      :root-location="businessDashLink('courses/' + this.uuid + '/evaluation/students')"
      icon="mdi-text-box-check-outline"
      :side-navigation="EVALUATION_NAVIGATION"
      navigation-route="evaluation"
      fill-height

      :no-content-condition="entity.modules.length === 0"
      :no-content-button-route="businessDashLink('courses/' + this.uuid + '/editor')"
      :no-content-button-text="MODULE_PERMISSIONS(['MODULE_WESTUDY__EDITOR'], true) ? 'CourseEditor' : null"
      no-content-text="NoTasksMessage"
      no-content-icon="mdi-text-box-check-outline"
      :loading="loading"
  >



    <template #default>
      <!-- Student Info-->
      <v-sheet
          :color="wsLIGHTCARD"
          class="wsRoundedHalf py-6 px-3 mt-5 mb-5"
          :style="`border : 1px solid ${wsBACKGROUND}`"
      >
        <div class="d-flex justify-space-between align-center pr-3">
          <div class="d-flex align-center text-no-wrap">
            <v-avatar class="mr-4 ml-1"  :color="wsACCENT"
                      size="80" key="profile_picture" >
              <v-img v-if="student.img" :src="student.img" transition="xxx">
                <template slot="placeholder">
                  <v-img src="@/assets/placeholder/user_blank.jpg" transition="xxx" />
                </template>
              </v-img>
              <v-icon  dark v-else>mdi-account-tie</v-icon>
            </v-avatar>
            <div>
              <h4 :style="`color : ${wsACCENT}`">{{ student.name }} </h4>
              <h5 class=" mt-1" > {{ student.email}}</h5>
              <h5> {{ student.phone}}</h5>
            </div>
          </div>

          <div>
            <h4 :style="`color : ${wsACCENT}`">
              <v-icon :color="completion.passed ? wsSUCCESS : wsACCENT">
                {{completion.passed ? "mdi-check-circle-outline" : 'mdi-progress-check' }}
              </v-icon>
              <span v-if="completion.passed">
              {{ $t('Passed') }}
            </span>
              <span v-else-if="completion.completion_percent > 0 ">
                {{  $t('InProcess') }}: {{ completion.completion_percent }}
            </span>
              <span v-else>
              {{ $t('NotStarted')  }}
            </span>

            </h4>
            <h4 :style="`color : ${wsACCENT}`" class="mt-2">

              {{ $t('CourseAverage') }}:
              {{ completion.result }}  </h4>
          </div>


        </div>

      </v-sheet>
      <v-sheet v-for="(module,i) in entity.modules" :key="i + 'semester'" :ref="'module_' + i" class="wsRounded ">
        <!-- Module-->
        <v-sheet class="wsRoundedLightTopSemi py-2 px-4" dark :color="wsACCENT">
          <h4> {{ module.name }}</h4>
        </v-sheet>
        <v-sheet :style="`border : 1px solid ${wsBACKGROUND}`" class="wsRoundedLightBottom mb-5">
          <template  v-for="(topic,j) in module.topics">
            <v-divider v-if="j > 0"  :style="`border-color : ${wsBACKGROUND}`"  :key="'topic.divider' + i + j" />
            <h5 class="px-4 py-2" :key="'topic.name' + i + j">
              {{ topic.name }}
            </h5>

            <template v-for="(component, k) in topic.components">
              <v-hover v-slot="{hover}"  :key="'component' + i + j + k ">
                <v-sheet :color="hover ? wsLIGHTCARD : null">



                  <v-divider  :style="`border-color : ${wsBACKGROUND}`" />

                  <div class="d-flex align-center justify-space-between px-4 py-1">

                    <!-- Component Icon and Name-->
                    <div class="d-flex align-center" >
                      <v-icon class="mr-3"
                              :color="component.result_passed ? wsATTENTION : 'grey'" >
                        {{ getEntityTypeIcon(component.entity_type ) }}
                      </v-icon>
                      <h5  :style="`color : ${component.result_passed ? wsACCENT : 'grey'}`"
                           class="hoverUnderline"
                           v-html="component.name"
                      />
                    </div>
                    <!-- Component Evaluation -->
                    <div
                        class="d-flex align-center"
                    >

                      <!-- Task Replies-->
                      <div v-if="component.need_reply" class="d-flex align-center ">
                        <v-btn
                            @click="openSutdentReply(component.uuid)"
                            text
                            height="38"
                            :color="component.student_replies_count > 0 ? wsATTENTION : wsDARKLIGHT"
                            class="mr-3 noCaps">
                          <v-icon class="mr-1"  >mdi-forum</v-icon>
                          <span v-if="component.student_replies_count > 0">
                                  {{ $t('Answers') }} : {{ component.student_replies_count }}
                              </span>
                          <span v-else>
                                {{ $t('NoResult')  }}
                              </span>

                        </v-btn>
                      </div>
                      <!-- Evaluation -->
                      <div v-if="component.is_evaluated" class="d-flex justify-end align-center ">
                        <ws-text-field
                            width="120"
                            @change="changeEvaluation($event, component , 'result_result')"
                            :value="component.result_result"
                            number
                            number-length="3"
                            :placeholder="component.pass_points || 50"
                            clearable
                        />
                      </div>
                      <!-- Test -->
                      <div class="d-flex align-center" v-else-if="component.is_test">

                        <ft-select
                            v-if="component.test_tries > 0"
                            @input="openResetTestTries(component)"
                            :items="[{text : $t('ResetTestTries') , value : 'reset' }]"
                        >
                          <v-btn text rounded :color="wsACCENT" class="noCaps pr-1 mr-4" style="margin-top: 6px">
                            <h4 class="text-no-wrap text-center">{{ $t('TestTries') }} : {{ component.test_tries }} <span v-if="component.test_tries_total">  / {{ component.test_tries_total }}  </span></h4>
                            <v-icon>mdi-menu-down</v-icon>
                          </v-btn>
                        </ft-select>

                        <v-btn
                            v-if="component.test_tries > 0"
                            @click="openStudentTest(component)"
                            text
                            height="38"
                            :color="wsATTENTION"
                            class="mr-3 noCaps">
                          <v-icon class="mr-1"  >mdi-eye</v-icon>
                          {{ $t('Preview')  }}
                        </v-btn>



                        <v-sheet
                            width="120" height="40"
                            :style="`border : 1px solid ${wsDARKLIGHT}`"
                            class="pa-1 d-flex justify-center align-center wsRoundedLight"
                        >


                          <h5 :style="`color : ${component.result_result ? 'wsACCENT' : wsDARKLIGHT}`"
                              :class="!component.result_result ? 'mr-5' : null"
                          >
                            {{ component.result_result || $t('NoResult')  }}
                          </h5>
                        </v-sheet>

                      </div>

                      <!-- Result passed -->
                      <v-sheet
                          v-else width="120" height="40"
                          :style="`border : 1px solid ${wsDARKLIGHT}`"
                          class="pa-1 d-flex justify-center wsRoundedLight">
                        <ft-select
                            @input="changeEvaluation($event, component , 'result_passed')" :items="passedSelect"
                            :value="component.result_passed"
                            fill-height
                        >
                          <h5 :style="`color : ${component.result_passed ? wsACCENT : wsDARKLIGHT}`"
                              class="pl-3"
                          >
                            {{ component.result_passed ? $t('Passed') : $t('NoResult')  }}
                          </h5>
                          <v-icon
                              :color="component.result_passed ? wsACCENT : wsDARKLIGHT"
                              class="mr-4"
                              small
                          >
                            mdi-menu-down
                          </v-icon>
                        </ft-select>
                      </v-sheet>

                    </div>

                  </div>

                </v-sheet>
              </v-hover>

            </template>

          </template>



        </v-sheet>
      </v-sheet>
    </template>

    <template #dialog>

      <!-- Reset Test Tries-->
      <ws-dialog
          v-model="displayResetTestTries"
          :title="$t('ResetTestTries')"
          :save-text="$t('Confirm')"
          @save="resetTestTries"
      >
        <h4 class="text-center mt-5">{{ $t('ResetTestTriesConfirmation') }}:</h4>
        <h3 class="text-center mt-6" :style="`color : ${wsACCENT}`">{{ selectedComponent.name }}</h3>
      </ws-dialog>

      <student-reply
          v-model="displayReplyDialog"
          :student-id="studentId"
          :component-id="selectedComponentId"
          @success="initPage(false)"
      />

      <test-preview-dialog
          v-if="displayTestResult"
          v-model="displayTestResult"
          :student-test-id="selectedStudentTest"
      />

    </template>

  </sub-page>
</template>

<script>
import {mapActions} from "vuex";
import studentReply from "@/components/pages/westudy/admin/course/UI/studentReply";
import testPreviewDialog
  from "@/components/pages/westudy/tests/testPreviewDialog.vue";

export default {
  name: "evaluationStudentsComponents",
  components : {
    studentReply,
    testPreviewDialog
  },
  props : {
    studentId : {
      type : String,
      course : ''
    },
    uuid : {
      type : String
    }
  },
  data() {
    return {
      loading : false,
      entity : {
        modules : []
      },
      completion : {},
      student : {},
      displayReplyDialog : false,
      selectedComponentId : null,
      selectedComponent : {},
      displayResetTestTries : false,
      displayTestResult : false,
      selectedStudentTest : null
    }
  },
  computed : {
    passedSelect() {
      return [
        { text : this.$t('Passed') , value : true },
        { text : this.$t('NoResult') , value : false }
      ]
    },
  },
  methods : {
    ...mapActions('evaluation' , [
        'GET_STUDENT_COMPONENTS_LIST',
        'EDIT_COMPONENT_EVALUATION',
        'RESET_STUDENT_TEST_TRIES'
    ]),

    openStudentTest(component) {
      if ( component.test_tries > 0 ) {
        this.selectedStudentTest = component.student_test_id
        this.displayTestResult = true
      }
    },

    async resetTestTries() {
      let data = {
        uuid : this.studentId,
        test_id : this.selectedComponent.test_id,
        component_id : this.selectedComponent.uuid
      }
      let result = await this.RESET_STUDENT_TEST_TRIES(data)
      if ( !result ) {
        return
      }

      this.selectedComponent.test_tries = 0
      this.selectedComponent.result_id = null
      this.selectedComponent.result_passed = null
      this.selectedComponent.result_result = null
      this.selectedComponent.result_status = null

      this.notify(this.$t('StudentTestTriesHasBeenReset'))
      this.displayResetTestTries = false
    },

    openResetTestTries(component) {
      this.selectedComponent = component
      this.displayResetTestTries = true

    },

    openSutdentReply(componentId) {
      this.selectedComponentId = componentId
      this.displayReplyDialog = true
    },

    async changeEvaluation(value, item, field) {
      if ( field === 'result_result' ) {
        if ( !value ) {
          value = 0
        }
        if (value < 0) {
          value = 0
        }
        if (value > 100 ) {
          value = 100
        }
      }
      let data = {
        uuid : item.uuid,
        student_id : this.studentId,
        [field] : value
      }
      if ( item.result_id ) {
        data.result_id = item.result_id
      }
      let result = await this.EDIT_COMPONENT_EVALUATION(data)

      if ( !item.result_passed && result.passed &&  item.student_replies_count > 0 ) {
        this.$store.state.courses.courseNewRepliesCounter--
      }

      if ( !result ) { return }

      if ( !item.result_id ) {
        item.result_id = result.uuid
        item.result_passed = result.passed
        this.items = JSON.parse(JSON.stringify(this.items))
      }
      item[field] = value
      item.result_passed = result.passed


      this.notify(this.$t('ChangesSaved'))
    },


    getEntityTypeIcon( type ) {

      let icon = ''
      switch ( type ) {
        case 'lecture'    :  icon = 'mdi-book-open'       ; break;
        case 'task'       :  icon = 'mdi-content-paste'                ; break;
        case 'task_video' :  icon = 'mdi-cast-education'               ; break;
        case 'test'       :  icon = 'mdi-order-bool-ascending-variant' ; break;
        case 'task_test'  :  icon = 'mdi-order-bool-ascending-variant' ; break;
        case 'video'      :  icon = 'mdi-video'                        ; break;
        case 'file'       :  icon = 'mdi-file'                         ; break;
        default : icon = 'mdi-bookmark'
      }
      return icon

    },

    async initPage(triggerLoading = true) {
      if ( triggerLoading ) {
        this.loading  = true
      }

      let result = await this.GET_STUDENT_COMPONENTS_LIST(this.studentId)
      this.loading  = false
      if ( !result ) {
        return
      }

      this.entity = result.course
      this.student = result.student_profile
      this.completion = result.completion
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>